import {
	Message
} from 'element-ui'
import {
	handleLoadMatchs,
	handleDetails
} from '@index/api/mymatchs/matchs';

import {
	handleQueryUserPublicMatchAuth
} from '@index/api/team/teamindex'
import {
	handlepcPayFee
} from '@index/api/mymatchs/matchinfo';
// import './index.scss';
export default {
	name: 'matchList',
	components: {

	},
	data() {
		return {
			payFee: '',
			QRcode: null,
			dialogTitle: '',
			payDialogVisible: false,
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			con: '',
			tableData: [],
			havAuth: false,
			queryForm: {},
			states: [{ value: '', label: '全部' }, { value: '0', label: '报名中' }, { value: '1', label: '报名截止' }, { value: '2', label: '比赛中' }, { value: '3', label: '比赛结束' }],
			payStates: [{ label: '全部', value: '' }, { label: '未付费', value: '0' }, { label: '推迟支付', value: '1' }, { label: '线上已支付', value: '2' }, { label: '线下已支付', value: '3' }, { label: '免费使用', value: '4' }],

		};
	},
	watch: {

	},
	created() {
		if (sessionStorage.getItem("userPasswrodNullFlag") == 'YES') {
			this.openSetPasswd(); //打开密码修改框
		}
		this.checkAuth();
		this.queryMatchs();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.queryMatchs();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.queryMatchs();
		},
		handlePrePage(currPage) { //上一页
			this.currPage = currPage;
			this.queryMatchs();
		},
		handleNextPage(currPage) { //下一页
			this.currPage = currPage;
			this.queryMatchs();
		},
		fastQueryMatchs() {
			this.currPage = 1;
			this.queryMatchs();
		},

		queryMatchs() {
			handleLoadMatchs({
				pageSize: this.pageSize,
				currPage: this.currPage,
				state: this.queryForm.state,
				payState: this.queryForm.payState,
				con: this.queryForm.con,
				sortWay: [{
					fieldName: 'createDate',
					sortWay: 'DESC'
				}]
			}).then(res => {
				if (res.status == 200) {
					this.tableData = res.data;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}

			})
		},
		toPublicMatch() { //跳转到发布比赛页面

			this.$router.push({
				name: "topublicmatch"
			})

		},
		toMatch(id) { //进入比赛
			handleDetails(id).then(res => {
				var _this=this;
				if (res.status == 200) {
					if (res.data.payState == '1'||res.data.payState == '2'||res.data.payState == '3'||res.data.payState == '4') {
						sessionStorage.setItem("currMatchId", id);
						this.$router.push({
							path: '/hosterMatchInfo',
							query: {
								menusShow: true
							}
						})
						return;
					}


					if (res.data.payState == '0'&&res.data.viewAuth == '0') {
						this.$confirm(
							'此比赛未付费，您可能需要向软件提供方支付网上报名系统的使用费，网上报名系统将在比赛前15天无法使用，如果已经支付请联系软件提供方取消此提示。点击确认进入比赛',
							'提示', {
							confirmButtonText: '我知道了,进入比赛',							
							cancelButtonText: '关闭',
							distinguishCancelAndClose: true,
							buttonSize: 'large',
							closeOnClickModal: false,
							confirmButtonClass: 'confirmButtonClass',
							type: 'warning',
						}).then(() => {							
							sessionStorage.setItem("currMatchId", id);
							_this.$router.push({
								path: '/hosterMatchInfo',
								query: {
									menusShow: true
								}
							})

						})
						return;
					} else {
						this.$confirm('该比赛无法进入，您可能需要向软件提供方支付费用，如果已经支付请联系软件提供方取消此提示(各参赛单位仍然可以正常登录)。详情请与软件供应商联系!',
							'提示', {
							confirmButtonText: '立即支付',
							showConfirmButton: false,
							cancelButtonText: '知道了!',
							distinguishCancelAndClose: true,
							buttonSize: 'large',
							closeOnClickModal: false,
							confirmButtonClass: 'confirmButtonClass',
							type: 'warning',
						}).then(() => {
							// if (res.data.matchServiceFee <= 0) {
							// 	Message({
							// 		message: '管理员还未设置服务费!',
							// 		type: 'error',
							// 		duration: 5 * 1000
							// 	})
							// 	return;
							// }

						});
					}

				} else {
					Message({
						type: 'error',
						message: res.msg,
						duration: 5000
					});

				}
			});



		},
		toMatch2(id) { //进入比赛（作废走线下支付）
			handleDetails(id).then(res => {
				if (res.status == 200) {
					if (res.data.payState == '0' && res.data.viewAuth == '1') { //未支付
						this.$confirm('该比赛无法进入，您可能需要向软件提供方支付费用，如果已经支付请联系软件提供方取消此提示(各参赛单位仍然可以正常登录)。详情请与软件供应商联系!',
							'提示', {
							confirmButtonText: '立即支付',
							showConfirmButton: false,
							cancelButtonText: '知道了!',
							distinguishCancelAndClose: true,
							buttonSize: 'large',
							closeOnClickModal: false,
							confirmButtonClass: 'confirmButtonClass',
							type: 'warning',
						}).then(() => {
							if (res.data.matchServiceFee <= 0) {
								Message({
									message: '管理员还未设置服务费!',
									type: 'error',
									duration: 5 * 1000
								})

								return;
							}
							this.payDialogVisible = true; //显示支付窗口
							if (res.data.matchName.length <= 20) {
								this.dialogTitle = "您正在支付比赛【" + res.data.matchName + "】的报名系统使用费";
							} else {
								this.dialogTitle = "您正在支付比赛【" + res.data.matchName.substring(0, 20) +
									"...】的报名系统使用费";
							}
							this.payFee = res.data.matchServiceFee;
							handlepcPayFee({
								bussId: res.data.id,
								type: '1',
								description: '报名系统使用费',
							}).then(res2 => {
								let bytes = new Uint8Array(res2);
								let storeData = "";
								let len = bytes.byteLength;
								for (let i = 0; i < len; i++) {
									storeData += String.fromCharCode(bytes[i]);
								}
								this.QRcode = "data:image/png;base64," + window.btoa(storeData);

							})

						});
					} else if (res.data.payState == '0') {
						this.$confirm(
							'此比赛未付费，您可能需要向软件提供方支付网上报名系统的使用费，网上报名系统将在比赛前15天无法使用，如果已经支付请联系软件提供方取消此提示。点击确认进入比赛',
							'提示', {
							confirmButtonText: '立即支付',
							showConfirmButton: false,
							cancelButtonText: '确定',
							distinguishCancelAndClose: true,
							buttonSize: 'large',
							closeOnClickModal: false,
							confirmButtonClass: 'confirmButtonClass',
							type: 'warning',
						}).then(() => {
							if (res.data.matchServiceFee <= 0) {
								Message({
									message: '管理员还未设置服务费!',
									type: 'error',
									duration: 5 * 1000
								})

								return;
							}
							this.payDialogVisible = true; //显示支付窗口
							if (res.data.matchName.length <= 20) {
								this.dialogTitle = "您正在支付比赛【" + res.data.matchName + "】的报名系统使用费";
							} else {
								this.dialogTitle = "您正在支付比赛【" + res.data.matchName.substring(0, 20) +
									"...】的报名系统使用费";
							}
							this.payFee = res.data.matchServiceFee;
							handlepcPayFee({
								bussId: res.data.id,
								type: '1',
								description: '报名系统使用费',
							}).then(res2 => {
								let bytes = new Uint8Array(res2);
								let storeData = "";
								let len = bytes.byteLength;
								for (let i = 0; i < len; i++) {
									storeData += String.fromCharCode(bytes[i]);
								}
								this.QRcode = "data:image/png;base64," + window.btoa(storeData);

							})

						}).catch(() => {
							sessionStorage.setItem("currMatchId", id);
							this.$router.push({
								path: '/matchinfo',
								query: {
									menusShow: true
								}
							})

						});

					} else {
						sessionStorage.setItem("currMatchId", id);
						this.$router.push({
							path: '/matchinfo',
							query: {
								menusShow: true
							}
						})
					}

				} else {
					Message({
						type: 'error',
						message: res.msg,
						duration: 5000
					});

				}
			});



		},
		filterPayStatus(value, row) {
			return row.payState === value;
		},
		// column, cellValue, index
		showPayStatusCN(row) {
			switch (row.payState) {
				case "0":
					return "未付费";
				case "1":
					return "推迟支付";
				case "2":
					return "<el-tag type='success'>线上已支付</el-tag>";
				case "3":
					return "线下已支付";
				case "4":
					return "免费使用";

				// <el-tag :type="(scope.row.payState === '2'||scope.row.payState === '3') ? 'success' : 'primary'"
				//   disable-transitions>{{scope.row.payState}}</el-tag>
			}
		},
		RefreshPage() {
			this.$router.go(0);
		},
		checkAuth() { //检查用户是否具有对应权限
			handleQueryUserPublicMatchAuth().then(res => {
				if (res.status == 200) {
					if (res.data.authStatus == '2') { //允许发布赛事（授权比赛）
						this.havAuth = true;
						return;
					}
				}
				this.havAuth = false;
			});
		},
		resetForm() {
			this.queryForm = {};
		}

	}
}